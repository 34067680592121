/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

function checkThings() {
  const thingsToWaitFor = document.getElementsByClassName("brand-wait")
  let result = 0
  for (let i = 0; i < thingsToWaitFor.length; i++) {
    result++
  }
  return result
}
function isPageReady() {
  let interval = setInterval(frame, 500)
  const loader = document.getElementById("___loader")

  frame()

  function frame() {
    if (checkThings() === 0) {
      clearInterval(interval)
      loader?.classList?.add("brand-loader-hide")
    }
  }
}

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
export const onInitialClientRender = () => {
  isPageReady()
}

export const onRouteUpdate = () => {
  const loader = document.getElementById("___loader")
  loader?.classList?.remove("brand-loader-hide")
  isPageReady()
}

/*
// Called when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

// Called when changing location is longer than 1 second.
exports.onRouteUpdateDelayed = () => {
  console.log("We can show loading indicator now")
}

export const onInitialClientRender = () => {
  const loader = document.getElementById("___loader")
  const gatsby = document.getElementById("___gatsby")
  const body = document.getElementById("body")

  setTimeout(() => {
    if (gatsby && loader && result === 0) {
      gatsby.style.display = "block"
      loader.style.opacity = 0
    }

    setTimeout(() => {
      if (body && gatsby && loader) {
        gatsby.style.opacity = 1
        loader.style.display = "none"
      }
    }, 1000)
  }, 1000)
}

*/
